<template>
    <div class="max-laptop:px-3 max-w-container mx-auto">
        <!-- LUG-CURRENT-RUN -->
        <WtcTransition name="fade">
            <div :key="currentChannel.Current?.Id" class="bg-surface-secondary mt-3 rounded border border-black/10">
                <div
                    class="bg-brand font-normal_light flex items-center rounded-t px-3 py-2 text-sm leading-none text-white"
                >
                    {{ $getStrByLng().CURRENT }}
                </div>
                <div class="max-tablet:flex-col flex min-h-16 flex-row border-b border-black/10 p-3">
                    <LugCurrentCompetitor
                        v-if="currentChannel.Current"
                        class="flex-1"
                        :competitor="currentChannel.Current"
                    ></LugCurrentCompetitor>
                    <LugCurrentRunSummary
                        v-if="currentChannel.Current"
                        class="max-tablet:mt-3"
                        :competitor="currentChannel.Current"
                    ></LugCurrentRunSummary>
                </div>
                <div class="max-tablet:gap-2 flex flex-row flex-wrap items-start justify-between gap-1 p-3">
                    <LugCurrentCompetitorLiveTiming
                        v-if="currentChannel.Current"
                        class="rounded border border-black/10"
                        :competitor="currentChannel.Current"
                    ></LugCurrentCompetitorLiveTiming>
                    <LugCurrentCompetitorSpeed
                        v-if="currentChannel.Current"
                        class="rounded border border-black/10"
                        :competitor="currentChannel.Current"
                    ></LugCurrentCompetitorSpeed>
                </div>
            </div>
        </WtcTransition>

        <div class="my-3">
            <div class="max-tablet:flex-col max-tablet:gap-3 flex flex-row items-start gap-1">
                <div class="max-tablet:w-full bg-surface-secondary flex-1 rounded border border-black/10">
                    <div class="font-normal_bold rounded-t px-3 py-2 text-sm">
                        {{ $getStrByLng().RESULTS }}
                    </div>
                    <div class="max-tablet:overflow-x-auto">
                        <LugResultsTable v-if="currentChannel.Rsc" :rsc="currentChannel.Rsc"></LugResultsTable>
                    </div>
                </div>
                <div class="max-tablet:w-full bg-surface-secondary rounded border border-black/10">
                    <div class="font-normal_bold rounded-t px-3 py-2 text-sm">
                        {{ $getStrByLng().START_LIST }} {{ currentChannel.UnitName }}
                    </div>
                    <LugStartListTable v-if="currentChannel.Rsc" :rsc="currentChannel.Rsc"></LugStartListTable>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useSledLiveChannel } from "@swisstiming/falcon-core";
import { WtcTransition } from "@swisstiming/webtec-kit";

import LugCurrentCompetitor from "./elements/LugCurrentCompetitor.vue";
import LugCurrentCompetitorLiveTiming from "./elements/LugCurrentCompetitorLiveTiming.vue";
import LugCurrentCompetitorSpeed from "./elements/LugCurrentCompetitorSpeed.vue";
import LugCurrentRunSummary from "./elements/LugCurrentRunSummary.vue";
import LugResultsTable from "./LugResultsTable.vue";
import LugStartListTable from "./LugStartListTable.vue";
const { currentChannel } = useSledLiveChannel();
</script>

<style></style>
