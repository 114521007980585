<template>
    <table class="w-full">
        <thead>
            <tr>
                <th class="w-12">{{ $getStrByLng().BIB }}</th>
                <th class="w-10 text-center">{{ $getStrByLng().HEADER_NAT }}</th>
                <th class="max-w-32 text-left">{{ $getStrByLng().NAME }}</th>
            </tr>
        </thead>
        <tbody>
            <template v-if="sortedStartList?.length > 0">
                <tr
                    v-for="competitor in sortedStartList"
                    :key="competitor.Id"
                    :class="[getTWHighlightCompetitorClass(competitor, currentCompetitorId)]"
                >
                    <td class="w-12">{{ competitor?.Bib }}</td>
                    <td class="w-10 text-left">
                        <FalconFlag :nat-code="competitor.Nationality"></FalconFlag>
                    </td>
                    <td class="max-w-32 text-left leading-none">
                        <WtcName :competitor-name="competitor"></WtcName>
                    </td>
                </tr>
            </template>
            <template v-else>
                <tr>
                    <WtcPlaceholder
                        class="text-brand font-normal_bold uppercase"
                        colspan="100"
                        tag="td"
                        text="There is no data available at the moment."
                    ></WtcPlaceholder>
                </tr>
            </template>
        </tbody>
    </table>
</template>
<script setup lang="ts">
import { FalconFlag, useSledLiveChannel, useSledTimingChannel } from "@swisstiming/falcon-core";
import { getTWHighlightCompetitorClass, IRsc, WtcName, WtcPlaceholder } from "@swisstiming/webtec-kit";
import { computed } from "vue";

const props = defineProps<{
    rsc?: IRsc;
}>();

const rscValue = computed(() => props.rsc?.Value);

const { sortedStartList } = useSledTimingChannel(rscValue);
const { currentCompetitorId } = useSledLiveChannel();
</script>
<style></style>
