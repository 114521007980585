import { getStrByLng } from "@swisstiming/webtec-kit";

import { SledCompetitorStatus } from "../enums/sled.enums";

export const mapStatusToLabel = (status: SledCompetitorStatus) => {
    switch (status) {
        case SledCompetitorStatus.AT_START:
            return getStrByLng().LABEL_AT_START;
        case SledCompetitorStatus.ON_TRACK:
            return getStrByLng().LABEL_ON_TRACK;
        case SledCompetitorStatus.FINISHED:
            return getStrByLng().LABEL_FINISHED;
        default:
            return "";
    }
};
