<template>
    <div>
        <div class="font-normal_bold p-2 text-sm">{{ $getStrByLng().LIVE_TIMING }}</div>
        <div class="mb-2 border-b border-black/10 pb-2 text-sm last:mb-0 last:border-b-0">
            <div class="mb-1 flex items-center px-2">
                <div class="font-normal_bold flex-1">{{ currentChannel.UnitName }}</div>

                <WtcTransition mode="out-in" name="slide-up">
                    <div
                        v-if="mappedStatusLabel"
                        :key="currentChannel.Stats?.Status"
                        class="rounded px-2 py-1 text-xs"
                        :class="statusStyle"
                    >
                        {{ mappedStatusLabel }}
                    </div>
                </WtcTransition>

                <WtcTransition mode="out-in" name="slide-up">
                    <div v-if="showAdvantage" :key="currentChannel.Stats.Advantage" class="ml-2 flex text-xs">
                        <span>{{ $getStrByLng().LABEL_ADVANTAGE }}:</span>
                        <span class="text-content-positive font-normal_bold ml-1">{{
                            currentChannel.Stats.Advantage
                        }}</span>
                    </div>
                </WtcTransition>

                <WtcTransition mode="out-in" name="slide-up">
                    <div v-if="currentCompetitor.Record" :key="currentCompetitor.Record" class="ml-2 flex text-xs">
                        <div class="mr-0.5">{{ $getStrByLng().RECORD }}:</div>
                        <SledRecordBadge
                            class="bg-surface-highlightLight text-content-highlight px-1 text-xs font-bold"
                            :record-string="currentCompetitor.Record"
                        ></SledRecordBadge>
                    </div>
                </WtcTransition>
            </div>
            <ul class="flex flex-wrap">
                <li
                    v-for="intermediate in currentCompetitor.Intermediate"
                    :key="intermediate.Name"
                    class="bg-surface-alternate min-w-20 text-center"
                >
                    <div
                        class="bg-brand-secondary max-tablet:px-1 font-normal_light h-5 px-4 py-1 text-xs uppercase leading-none text-white"
                    >
                        {{ intermediate.Name }}
                    </div>
                    <div class="font-normal_bold min-h-12 p-2 text-sm leading-none">
                        <span v-highlight="intermediate.Result">
                            {{ intermediate.Result }}
                        </span>
                        <span class="font-regular text-xs font-normal pl-1">{{
                            getWrappedValue(isRelay ? intermediate.Rank : intermediate.CumulativeRank, "[", "]")
                        }}</span>
                        <br />
                        <template v-if="intermediate.Tendency">
                            <FalconBehind
                                :behind="intermediate.Tendency"
                                class="font-regular text-xs font-normal"
                                tag="span"
                            ></FalconBehind>
                        </template>
                    </div>
                </li>
            </ul>
        </div>

        <table v-if="isRelay" class="lug__team-member-list w-full">
            <thead>
                <tr>
                    <th class="text-left">{{ $getStrByLng().NAME }}</th>
                    <th>{{ $getStrByLng().HEADER_RESULT }}</th>
                    <th>{{ $getStrByLng().BEHIND }}</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="teamMember in competitor.Children"
                    :key="teamMember.Id"
                    class="team-member"
                    :class="[getTWHighlightTeamMemberClass(teamMember, currentCompetitor.Id)]"
                >
                    <td class="text-left">
                        <WtcName :competitor-name="teamMember"></WtcName>
                    </td>
                    <td>{{ teamMember.Result }}</td>
                    <td>
                        <FalconBehind
                            :behind="teamMember.Behind"
                            class="font-regular text-xs font-normal"
                            tag="span"
                        ></FalconBehind>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script setup lang="ts">
import { FalconBehind, ISledCurrentCompetitor, useSledLiveChannel } from "@swisstiming/falcon-core";
import { mapStatusToLabel, SledCompetitorStatus, SledRecordBadge } from "@swisstiming/sled-core";
import { getTWHighlightTeamMemberClass, getWrappedValue, WtcName, WtcTransition } from "@swisstiming/webtec-kit";
import { computed } from "vue";

const props = defineProps<{
    competitor: ISledCurrentCompetitor;
}>();

const { currentChannel, isRelay } = useSledLiveChannel();
const currentCompetitor = computed(() =>
    props.competitor.IsTeam && props.competitor.CurrentChildId !== undefined
        ? props.competitor.Children?.[props.competitor.CurrentChildId]
        : props.competitor
);

const showAdvantage = computed(() => {
    const advantage = parseFloat(currentChannel.value?.Stats?.Advantage);
    const status = currentChannel.value?.Stats?.Status;

    return !isNaN(advantage) && advantage < 0 && status !== SledCompetitorStatus.FINISHED;
});

const mappedStatusLabel = computed(() => mapStatusToLabel(currentChannel.value?.Stats?.Status));

const statusStyle = computed(() => {
    switch (currentChannel.value?.Stats?.Status) {
        case SledCompetitorStatus.AT_START:
            return "bg-surface-comingup text-content-comingup";
        case SledCompetitorStatus.ON_TRACK:
            return "bg-surface-running text-content-running";
        case SledCompetitorStatus.FINISHED:
            return "bg-surface-finished text-content-finished";
        default:
            return "bg-black text-white";
    }
});
</script>
<style lang="scss">
.lug__team-member-list {
    tr.current-team-member {
        > td {
            @apply bg-surface-highlightLight !important;

            &:first-child {
                @apply before:h-full before:border-l-4 before:border-surface-highlight before:content-['*'] before:absolute before:left-0 before:top-0 before:text-transparent relative;
            }
        }
    }
}
</style>
