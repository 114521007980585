import "./styles/styles.scss";

import { falconPlugin } from "@swisstiming/falcon-core";
import { SportCodes } from "@swisstiming/webtec-kit";
import { createApp } from "vue";
import { createRouter, createWebHashHistory } from "vue-router";

import App from "./App.vue";
import LugApp from "./views/LugApp.vue";

const router = createRouter({
    history: createWebHashHistory(),
    routes: [{ path: "/", component: LugApp }]
});

createApp(App)
    .use(falconPlugin, {
        title: "Luge Web Results",
        router,
        storeOptions: {
            sportCode: SportCodes.LUGE,
            initOptions: {
                withCurrentEvent: true,
                withCisConfig: true,
                withSeason: true
            },
            iframeIntegration: {
                required: true,
                domains: ["fil-luge.org", "falcon-monitor.liveresults.uat-sports.swisstiming.com"]
            }
        }
    })
    .mount("#app");
