import {
    CHANNELS_NAMES,
    ChannelStatus,
    checkAndSetupIframeIntegration,
    COMMON_QUERY_NAMES,
    CommonLocalisationArgument,
    CONNECTION_STATUS,
    createDevPushClientOptions,
    createProdPushClientOptions,
    createUatPushClientOptions,
    DEFAULT_LANGUAGE_CODE,
    ENVIRONMENT_TYPES,
    getApplicationName,
    getCurrentTournamentChannel,
    getEnvironmentFromMode,
    getNamespaceSuffixFromMode,
    getQueryParamDictionary,
    handleError,
    handleWarn,
    ICurrentEventChannel,
    IframeIntegrationOptions,
    IframeIntegrationState,
    IMisConfigChannel,
    IMisConfigStatusChannel,
    IPushClientOptions,
    ISeasonChannel,
    IStandingDataChannel,
    MODES,
    NAMESPACE_TYPES,
    SportCodes,
    useChannelStore,
    usePromisedChannel,
    useTournamentChannelNames,
    useTranslationStore,
    useWatchedChannel
} from "@swisstiming/webtec-kit";
import { merge } from "lodash-es";
import { defineStore } from "pinia";
import PushServerClient from "pushserver-client/dist/pushServerClient";
import { computed, reactive, ref } from "vue";

import falconStrings from "../localisation/en.json";
interface IFalconData {
    currentEventData?: ICurrentEventChannel;
    misConfigData?: IMisConfigChannel;
    seasonData?: ISeasonChannel;
    standingData?: IStandingDataChannel;
}

interface IFalconConfig {
    environment?: ENVIRONMENT_TYPES;
    namespace?: string;
    namespaceSuffix?: NAMESPACE_TYPES;
    tournamentId?: string;
    seasonId?: string;
    sportCode?: SportCodes;
    mode?: MODES;
    lng?: CommonLocalisationArgument;
    profile?: string;
    isConfigLoaded?: boolean;
    iframeIntegration: IframeIntegrationState;
}

export interface IFalconStoreOptions {
    defaultNameSpace?: string;
    pushClientOptions?: IPushClientOptions;
    sportCode?: SportCodes;
    initOptions?: {
        withCurrentEvent: boolean;
        withCisConfig: boolean;
        withSeason: boolean;
    };
    iframeIntegration?: IframeIntegrationOptions;
}

export const useFalconStore = defineStore("FalconStore", () => {
    // https://github.com/vuejs/pinia/issues/978 StoreSetup
    // https://pinia.vuejs.org/introduction.html#basic-example

    const channelStore = useChannelStore();
    const { setStrings } = useTranslationStore();

    // <editor-fold desc="State">

    const data: IFalconData = reactive({ misConfigData: {} });
    const config: IFalconConfig = reactive({
        environment: computed(() => getEnvironmentFromMode(config.mode)),
        namespace: undefined,
        namespaceSuffix: computed(() => getNamespaceSuffixFromMode(config.mode)),
        tournamentId: computed(() => qParams.value.event ?? data?.currentEventData?.EventId?.toUpperCase()),
        seasonId: computed(() => qParams.value.season ?? data?.currentEventData?.SeasonId?.toUpperCase()),
        mode: computed(() => (qParams.value.mode ?? import.meta.env.MODE) as MODES),
        lng: computed(() => (qParams.value.lng ?? DEFAULT_LANGUAGE_CODE) as CommonLocalisationArgument),
        profile: computed(() => qParams.value.profile?.toLowerCase()),
        sport: undefined,
        iframeIntegration: { required: false, domains: [], authorized: false }
    });

    // </editor-fold>

    // <editor-fold desc="Getters">
    const connected = computed(() => channelStore.PS_ConnectionStatus);
    const hasTranslation = ref(false);
    const isConnected = computed(() => channelStore.PS_ConnectionStatus === CONNECTION_STATUS.CONNECTED);
    const isReady = computed(() => isConnected.value && hasTranslation.value);

    const qParams = computed(() => getQueryParamDictionary<COMMON_QUERY_NAMES>());

    const channelNames = useTournamentChannelNames(
        computed(() => config.tournamentId),
        computed(() => config.seasonId)
    ).channelNames;
    // </editor-fold>

    // <editor-fold desc="Actions">

    const requestTranslation = async () => {
        try {
            const locale = await fetch(`/localisation/${config.lng}.json`);
            const localeJson = await locale.json();

            if (config.lng !== DEFAULT_LANGUAGE_CODE) {
                // get english too to be able to fallback to english values.
                const localeEn = await fetch(`/localisation/${DEFAULT_LANGUAGE_CODE}.json`);
                const localeEnJson = await localeEn.json();

                setStrings(merge(falconStrings, localeEnJson, localeJson));
            }
            setStrings(merge(falconStrings, localeJson));
        } catch (err) {
            console.error(`Failed to request locales of given language: ${config.lng}`, err);
        } finally {
            hasTranslation.value = true;
        }
    };
    // Request translations
    requestTranslation().catch(handleWarn);

    const initFalconStore = (options: IFalconStoreOptions) => {
        config.sportCode = options.sportCode;

        if (options.iframeIntegration?.required === true) {
            config.iframeIntegration = checkAndSetupIframeIntegration(options.iframeIntegration, (options) => {
                const isViteDevEnvironment = import.meta.env.DEV;
                const isModeDev = config.mode === MODES.DEV || config.mode === MODES.DEV_SHORT;
                const isModeUAT = config.mode === MODES.UAT;
                return isViteDevEnvironment || isModeDev || isModeUAT ? false : options.required;
            });
        }

        // sport-code is now optional, e.g. for apps which are not sport-related like the falcon-monitor
        if (qParams.value.namespace || options.sportCode) {
            config.namespace = (
                qParams.value.namespace ?? getApplicationName(options.sportCode, config.namespaceSuffix)
            )?.toUpperCase();
        }

        // set profile data-attribute on html-element, which can be utilized for theming, etc.
        if (config.profile) {
            document.documentElement.setAttribute("data-profile", config.profile);
        } else {
            document.documentElement.setAttribute("data-profile", "default");
        }

        const pushClientOptions = {
            ...options.pushClientOptions,
            frontendIdentifier: __APP_NAME__,
            defaultNamespace: config.namespace
        };

        const host = qParams.value.host;
        const useHttps = qParams.value.useHttps;

        if (host) {
            pushClientOptions.host = host;
        }

        if (useHttps !== undefined && useHttps !== null) {
            pushClientOptions.useHTTPS = useHttps === "true";
        }

        switch (config.mode) {
            case MODES.DEV:
            case MODES.DEV_SHORT:
                channelStore.connect(PushServerClient, createDevPushClientOptions(pushClientOptions));
                break;
            case MODES.UAT:
                channelStore.connect(PushServerClient, createUatPushClientOptions(pushClientOptions));
                break;
            default:
                channelStore.connect(PushServerClient, createProdPushClientOptions(pushClientOptions));
        }

        // When initialized with current event, the current event channel and standing data channel is subscribed and all common channel names are derived
        if (options.initOptions?.withCurrentEvent) {
            subscribeCurrentEvent();
        }

        if (options.initOptions?.withCisConfig) {
            subscribeMISConfig().catch(handleError);
        }

        if (options.initOptions?.withSeason && options.initOptions?.withCurrentEvent) {
            subscribeSeason();
        }
    };

    /**
     * Subscribes current event and standing data channel and determines common channel names from tournament id.
     */
    const subscribeCurrentEvent = () => {
        data.currentEventData = useWatchedChannel(
            ref(qParams.value.event ? getCurrentTournamentChannel(qParams.value.event) : CHANNELS_NAMES.CURRENT_EVENT)
        ).content as ICurrentEventChannel;

        data.standingData = useWatchedChannel(computed(() => channelNames.value?.standings))
            .content as IStandingDataChannel;
    };

    const subscribeMISConfig = async () => {
        try {
            let targetChannel: string;

            const configStatus = await usePromisedChannel<IMisConfigStatusChannel>(CHANNELS_NAMES.CIS_CONFIG_STATUS);

            const eventChannel = await usePromisedChannel<ICurrentEventChannel>(
                qParams.value.event ? getCurrentTournamentChannel(qParams.value.event) : CHANNELS_NAMES.CURRENT_EVENT
            );

            const hasSeasonConfig =
                configStatus.EventGroups?.[eventChannel.SeasonId].Events?.[eventChannel.EventId]?.HasCISConfig ||
                configStatus.EventGroups?.[eventChannel.SeasonId?.toUpperCase()].Events?.[
                    eventChannel.EventId?.toUpperCase()
                ]?.HasCISConfig;

            const hasEventConfig =
                configStatus.Events?.[data.currentEventData.EventId]?.HasCISConfig ||
                configStatus.Events?.[data.currentEventData.EventId?.toUpperCase()]?.HasCISConfig;

            if (hasSeasonConfig) {
                targetChannel = `${data.currentEventData.SeasonId}_${data.currentEventData.EventId}_${CHANNELS_NAMES.CIS_CONFIG}`;
            } else if (hasEventConfig) {
                targetChannel = `${data.currentEventData.EventId}_${CHANNELS_NAMES.CIS_CONFIG}`;
            } else {
                targetChannel = CHANNELS_NAMES.CIS_CONFIG;
            }
            const { content, status } = useWatchedChannel(ref(targetChannel));
            data.misConfigData = content as IMisConfigChannel;
            // @ts-ignore
            config.isConfigLoaded = computed(() => status.value === ChannelStatus.ANSWERED);
        } catch (err) {
            const { content, status } = useWatchedChannel(ref(CHANNELS_NAMES.CIS_CONFIG));
            data.misConfigData = content as IMisConfigChannel;
            // @ts-ignore
            config.isConfigLoaded = computed(() => status.value === ChannelStatus.ANSWERED);
        }
    };

    const subscribeSeason = () => {
        data.seasonData = useWatchedChannel(computed(() => channelNames.value?.season)).content as ISeasonChannel;
    };

    // </editor-fold>

    return {
        isReady,
        config,
        data,
        qParams,
        connected,
        isConnected,
        channelNames,
        initFalconStore
    };
});
