<template>
    <table class="w-full">
        <thead>
            <tr>
                <th class="sticky left-0 w-12 min-w-12">{{ $getStrByLng().RANK }}</th>
                <th class="w-16 min-w-16">{{ $getStrByLng().HEADER_NAT }}</th>
                <th class="min-w-32 text-left">{{ $getStrByLng().NAME }}</th>
                <th v-for="run in runsOrdered" :key="run.Name">
                    {{ run.Name }}
                </th>
                <th v-if="!isRelay" class="current-run">{{ timingChannel?.NameShort }}</th>
                <th class="w-16 min-w-16">{{ $getStrByLng().TOTAL }}</th>
                <th class="w-16 min-w-16">{{ $getStrByLng().BEHIND }}</th>
            </tr>
        </thead>
        <WtcTransitionGroup class="relative" name="list-sort" tag="tbody">
            <template v-if="sortedResultList?.length > 0">
                <tr
                    v-for="competitor in sortedResultList"
                    :key="competitor.Id"
                    :class="[getTWHighlightCompetitorClass(competitor, currentCompetitorId)]"
                >
                    <FalconRankIrm class="!sticky left-0" :competitor="competitor"></FalconRankIrm>
                    <FalconFlag class="w-12" :nat-code="competitor.Nationality" tag="td"></FalconFlag>
                    <td class="text-left leading-none">
                        <WtcName :competitor-name="competitor"></WtcName>
                    </td>
                    <td v-for="run in competitor.Runs" :key="run.Name" class="font-normal_bold leading-none">
                        {{ run.Result }} <br /><span class="font-normal_regular text-xs font-normal">{{
                            getWrappedValue(run.Rank, "[", "]")
                        }}</span>
                    </td>
                    <td v-if="!isRelay" class="current-run font-normal_bold leading-none">
                        {{ lastIntermediate(competitor)?.Result }}
                        <br /><span class="font-normal_regular text-xs font-normal">{{
                            getWrappedValue(lastIntermediate(competitor)?.Rank, "[", "]")
                        }}</span>
                    </td>
                    <td>{{ competitor.Result }}</td>
                    <td>{{ competitor.Behind }}</td>
                </tr>
            </template>
            <template v-else>
                <tr>
                    <WtcPlaceholder
                        class="text-brand font-normal_bold uppercase"
                        colspan="100"
                        tag="td"
                        text="There is no data available at the moment."
                    ></WtcPlaceholder>
                </tr>
            </template>
        </WtcTransitionGroup>
    </table>
</template>
<script setup lang="ts">
import {
    FalconFlag,
    FalconRankIrm,
    ISledRun,
    useSledLiveChannel,
    useSledTimingChannel
} from "@swisstiming/falcon-core";
import { SledRecordBadge } from "@swisstiming/sled-core";
import {
    getTWHighlightCompetitorClass,
    getWrappedValue,
    ICompetitorDetail,
    ICompetitorIntermediate,
    IRsc,
    WtcName,
    WtcPlaceholder,
    WtcTransitionGroup
} from "@swisstiming/webtec-kit";
import { orderBy } from "lodash-es";
import { computed } from "vue";

const props = defineProps<{
    rsc?: IRsc;
}>();
const rscValue = computed(() => props.rsc?.Value);

const { sortedResultList, timingChannel, isRelay } = useSledTimingChannel(rscValue);
const { currentCompetitorId } = useSledLiveChannel();

const runsOrdered = computed((): ISledRun[] => orderBy(timingChannel?.value.Runs, "Number"));

const lastIntermediate = (competitor: ICompetitorDetail): ICompetitorIntermediate => competitor.Intermediate?.at(-1);
</script>
<style lang="scss">
tr.current-competitor {
    > td {
        @apply bg-surface-highlightLight;

        &:first-child {
            @apply before:h-full before:border-l-4 before:border-surface-highlight before:content-['*'] before:absolute before:left-0 before:top-0 before:text-transparent relative;
        }
    }

    .current-run {
        @apply after:border-b-2 after:border-surface-highlight after:content-['*'] after:absolute after:bottom-0 after:left-0 after:text-transparent after:w-full relative;
    }
}
</style>
